import color from "@definitions/mui/color";
import { fontFamSecondary } from "@definitions/mui/custom";
import { makeStyles } from "@mui/styles";

type TSInputBase = {
  error?: boolean | any;
  disabled?: boolean | any;
}

const baseInput: any = {
  borderRadius: 5,
  border: `1px solid ${color.grey.light}`,
  color: color.general.mid,
  fontFamily: fontFamSecondary,
  padding: "8px 16px"
};

const useStyles = makeStyles(() => ({
  container: ({ error, disabled }: TSInputBase) => {
    const styleInput = {
      ...baseInput,
      '&:focus-within': {
        borderColor: color.inactive.secondary,
      },
      alignItems: 'center',
      backgroundColor: 'white',
      display: 'flex',
      position: 'relative',
    };

    if (disabled) {
      Object.assign(styleInput, {
        backgroundColor: color.inactive.primary,
      });
    }
    if (error) {
      Object.assign(styleInput, {
        borderColor: color.fault
      });
    }

    return styleInput;
  },
  "input": {
    '&[type=number]': {
      '-webkit-appearance': 'textfield',
      '-moz-appearance': 'textfield'
    },
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&:-ms-input-placeholder': {
      color: color.placeholder
    },
    '&::placeholder': {
      color: color.placeholder
    },
    '&:focus': {
      boxShadow: 'none'
    },
    fontSize: 16,
  },
  "multiline": {
    padding: 0
  },
  "root": {
    width: '100%',
  },
}));

export default useStyles;
