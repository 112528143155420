import React from "react";
import MuiInputBase from "@mui/material/InputBase";
import useStyles from "./styles";
import { TMuiInputBase } from "./type";

const InputBase: React.FC<any> = React.forwardRef<HTMLInputElement>(
  (props: TMuiInputBase, ref: any) => {
    const {
      value,
      error,
      startAdornment,
      endAdornment,
      disabled,
      onChange,
      dataTestID,
      ...inputBaseProps
    } = props;
    const { container, ...inputBaseClasses } = useStyles({ error: !!error, disabled });

    return (
      <div className={container}>
        {startAdornment}
        <MuiInputBase
          inputProps={{ "data-testid": dataTestID }}
          classes={inputBaseClasses}
          disabled={disabled}
          inputRef={ref}
          value={value}
          onChange={onChange}
          {...inputBaseProps}
        />
        {endAdornment}
      </div>
    );
  },
);

export default InputBase;
