import React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Text from "../Text";
import useStyles from "./styles";
import { TFormControl } from "./type";
import { useLanguage } from "@utils/useLanguage";

const FormControl: React.FC<any> = (props: TFormControl) => {
  const {
    label,
    optional,
    children,
    id,
    error,
    hint,
    maxlength,
    field,
    info,
    HelperProps,
    ...rest
  } = props;
  const classes = useStyles();
  const { language } = useLanguage();

  const renderLabel = () => {
    if (!label) return null;
    return (
      <Text className={classes["label"]} variant="subtitle1">
        {label}
        {optional && (
          <Box component="span" className={classes["optional"]}>
            <Text color="inactive-secondary" variant="caption1">
              ({language === "en" ? "optional" : "opsional"})
            </Text>
          </Box>
        )}
      </Text>
    );
  };

  const renderChildrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child as React.ReactElement<any>, { error, id });
    }
    return child;
  });

  const renderHelperText = () => {
    const helperText = error || hint;
    const limitVal = !!maxlength && field.value?.length;

    return (
      <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
        <Collapse in={!!helperText}>
          <Text
            color={error ? "fault" : "general-main"}
            display="block"
            minHeight={"15px"}
            variant="caption1"
            {...HelperProps}
          >
            {helperText}
          </Text>
        </Collapse>
        <Collapse in={!!maxlength}>
          <Text color="inactive-secondary" variant="caption1">
            {limitVal || 0}/{maxlength} {info}
          </Text>
        </Collapse>
      </Box>
    );
  };

  return (
    <Box {...rest}>
      {renderLabel()}
      {renderChildrenWithProps}
      {renderHelperText()}
    </Box>
  );
};

export default FormControl;
