import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  "label": {
    alignItems: "center",
    display: "flex",
    marginBottom: 6,
  },
  "optional": {
    display: "flex",
    alignItems: "center",
    marginLeft: 5,
  },
}));

export default useStyles;
