import { APLHANUMERIC, EMAILORNUMBERREGEX } from "@utils/regex";
import * as Yup from "yup";

const validateForm = Yup.object().shape({
  username: Yup.string().required("username is required").matches(EMAILORNUMBERREGEX),
  password: Yup.string()
    .required("password is required")
    .min(8, "String length must be greater than or equal to 8").matches(APLHANUMERIC),
});

export default validateForm;