import { canonicalLink } from "@helpers/url";
import Auth from "@layouts/Auth";
import { NextPageContext } from "next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { ReactElement } from "react";
import Login from "../containers/Login/Login";
import { localeKey } from "@constants/locale-key";

const LoginPage = () => {
  return <Login />;
};

LoginPage.getLayout = function getLayout(page: ReactElement) {
  return (
    <>
      <Auth title="Login">{page}</Auth>
    </>
  );
};

export async function getServerSideProps(context: NextPageContext) {
  const locale = `${context.locale}`;
  const EN = locale === "en";
  // const isAllowLogin = (process.env.NEXT_PUBLIC_ALLOW_LOGIN ?? "true") === "true";
  return {
    // notFound: !isAllowLogin,
    props: {
      ...(await serverSideTranslations(locale ?? "id", localeKey)),
      title: EN
        ? "Login for MyTEnS Solutions and Services"
        : "Login untuk Solusi dan Layanan MyTEnS",
      description: EN
        ? "MyTEnS offers the best solutions and services to help company digitalization. Join MyTEnS right now"
        : "MyTEnS menawarkan solusi dan layanan terbaik untuk membantu digitalisasi perusahaan. Bergabung dengan MyTEnS sekarang juga",
      canonical: canonicalLink("/login", locale ?? "id"),
      imageUrl: "https://ik.imagekit.io/zwip3gy73r/dev-mytens-customer-assets/bglogin.png?tr=w-360"
    },
  };
}

export default LoginPage;
