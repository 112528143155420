import { ChangeEvent, useEffect, useState } from "react";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import { IMG_AKUN_TERKUNCI } from "@config/images";
import resetApiState from "@helpers/resetApiState";
import {
  getStorageAuthData,
  getStorageRememberMe,
} from "@helpers/storage";
import useCountdown from "@helpers/useCountdown";
import { gtmCustomEvent } from "@helpers/analytics";
import useResponsive from "@helpers/useResponsive";
import { setWarning } from "@redux/actions";
import { setClear } from "@redux/slices/warning";
import { setCloseSnackbar, setOpenSnackbar } from "@redux/slices/rootSnackbar";
import { useLanguage } from "@utils/useLanguage";
import useSubmitLogin from "./useSubmitLogin";
import type { TLoginForm } from "../type";
import { ticker } from "../style";
import { useAccountContext } from "@contexts/AccountProvider";
import ROUTES from "@config/routes";


const useAction = () => {
  const router = useRouter();
  const { resetAll } = resetApiState();
  const { language } = useLanguage();
  const { setIsAuth } = useAccountContext();
  const appcategory = "customer";
  const authStorage = getStorageAuthData();
  const rememberMe = getStorageRememberMe();
  const dispatch = useDispatch();
  const isSM = useResponsive("sm");
  const [visiblePass, setVisiblePass] = useState(false);
  const [isLoadButton, setIsLoadButton] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | number | null>("");
  const { counter, setCounter } = useCountdown(0);
  const { t } = useTranslation("login");

  const [initialValueForm, setInitialValueForm] = useState<Partial<TLoginForm>>({
    username: "",
    password: "",
  });

  useEffect(() => {
    sessionStorage.clear();
    resetAll();
    setIsChecked(!!(rememberMe === "true"));
    setInitialValueForm({ ...authStorage });
  }, []);

  const handleClickPass = () => {
    setVisiblePass(!visiblePass);
  };

  const onRememberChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.currentTarget.checked);
  };

  const onSubmitForm = (values: Partial<TLoginForm>) => {
    setIsLoadButton(true);
    useSubmitLogin({ ...values }, isChecked, language, appcategory)
      .then((response) => {
        setErrorMessage("");
        dispatch(setCloseSnackbar());
        // checkingSubscribe();

        setIsAuth(true);
        // Send GTM Custom Event
        gtmCustomEvent(
          {
            eventAction: "Submit Login",
            eventCategory: "Submit Form",
            eventLabel: "Sukses Login",
          },
          "Login",
        );

        if (response.code === "SUCCESS") {
          const { data } = response;

          if (data.hasServices) {
            window.location.href = `/${router.locale === "en" ? "en/" : ""}dashboard`; // required to analytic requirement to sending user id
          } else {
            router.push(ROUTES.HOME());
          }

        }
      })
      .catch((response) => {
        if (typeof response?.counter === "number" && response?.counter > 0) {
          setCounter(response?.counter);
          setErrorMessage(response?.message);
        } else {
          if (isSM) {
            dispatch(
              setOpenSnackbar({
                message: response.message as string,
                anchor: "bottom-center",
                variant: "failed",
                duration: 4000,
                action: [
                  {
                    label: "Tutup",
                    onClick: () => {
                      dispatch(setCloseSnackbar());
                    },
                  },
                ],
              }),
            );
          }
          setErrorMessage(response.message as string);
        }
      })
      .finally(() => {
        setIsLoadButton(false);
      });
  };

  useEffect(() => {
    if (counter > 0 && isSM) {
      dispatch(
        setWarning({
          open: true,
          image: <img src={IMG_AKUN_TERKUNCI} alt="akun-terkunci" />,
          maxWidth: "xs",
          messages: [[t("locked"), "", "700", "title"], [errorMessage]],
          additionalContent: <Box css={ticker}>{counter}</Box>,
        }),
      );
    } else {
      dispatch(setClear());
    }
  }, [counter]);

  const onHelpClick = () => {
    dispatch(setCloseSnackbar());
    router.push("/bantuan");
  };

  return {
    handleClickPass,
    visiblePass,
    onRememberChange,
    onSubmitForm,
    initialValueForm,
    isLoadButton,
    isChecked,
    errorMessage,
    counter,
    setErrorMessage,
    language,
    onHelpClick,
    isSM,
  };
};

export default useAction;
