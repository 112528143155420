import { TLoginForm } from "@containers/Login/type";
import { AxiosRequestConfig } from "axios";

export const baseUrl: string = process.env.NEXT_PUBLIC_BASE_URL as string;

const clientId: string = process.env.NEXT_PUBLIC_CLIENT_ID as string;
const clientSecret: string = process.env.NEXT_PUBLIC_CLIENT_SECRET as string;

const LoginServices = {
  PostGenerateToken: (): AxiosRequestConfig => ({
    url: `${baseUrl}/users-management/v3/auth/generatetoken`,
    // url: `/api/generatetoken`,
    method: "POST",
    data: {
      clientId,
      clientSecret,
    },
  }),
  PostLogin: (
    param: {
      accessToken: string;
      loginInput: Partial<TLoginForm>;
    },
    lang: string,
    appcategory: string,
  ): AxiosRequestConfig => ({
    url: `/api/login`,
    method: "POST",
    data: param.loginInput,
    headers: {
      Authorization: `Bearer ${param.accessToken}`,
      lang: lang,
      appcategory: appcategory,
    },
  }),
};

export default LoginServices;
