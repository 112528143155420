import { useAppDispatch } from "@redux/store";
import { homeApi } from "@repositories/home/home.redux";
import { myProjectServicesApi } from "@repositories/my-project-services/my-project-services.redux";
import { notificationApi } from "@repositories/notification/notification.redux";
import { privilegeApi } from "@repositories/privilege/privilege.redux";

const resetApiState = () => {
  const dispatch = useAppDispatch();

  const resetAll = () => {
    dispatch(homeApi.util.resetApiState());
    dispatch(myProjectServicesApi.util.resetApiState());
    dispatch(notificationApi.util.resetApiState());
    dispatch(privilegeApi.util.resetApiState());
  };

  return {
    resetAll,
  };
};

export default resetApiState;
