import { InputAdornment, IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import React from "react";

const VisiblePassword = (props: {visiblePass: boolean, handleClickPass: () => void}) => {
  const { visiblePass, handleClickPass } = props;

  return (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={handleClickPass}
        edge="end"
      >
        {visiblePass ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    </InputAdornment>
  );
};

export default VisiblePassword;
