import { useState, useEffect } from "react";

const UseCountdown = (initNum: number) => {
  const [counter, setCounter] = useState(initNum);

  useEffect(() => {
    counter > initNum && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  return {
    counter,
    setCounter,
  };
};

export default UseCountdown;
