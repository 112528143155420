import { LOGIN_SERVICES } from "@repositories/login/login.hooks";
import { encryptStorage } from "@helpers/storageService";
import { setProperty } from "@helpers/firebase/analytics";
import { setAuthData, setRememberMe } from "@helpers/storage";
// import getGenerateToken from "@helpers/getGenerateToken";
import type { IBaseResponse } from "src/utils/base-response";
import type { TLoginForm } from "../type";

const useSubmitLogin = async (
  formValue: Partial<TLoginForm>,
  rememberMe: boolean,
  lang: string,
  appcategory: string,
) => {
  // const generateToken: string = await getGenerateToken();
  const firebaseToken = localStorage.getItem("fcm_token") ?? "";
  let resp: IBaseResponse;

  // if (generateToken) {
  try {
    resp = await LOGIN_SERVICES.POST_LOGIN(
      {
        accessToken: "",
        loginInput: { ...formValue, firebaseToken },
      },
      lang,
      appcategory,
    );

    setProperty("email", formValue.username as string); // set firebase analytics property

    const { accessToken, refreshToken, accessTokenExpiresIn, refreshTokenExpiresIn } = resp.data;

    setAuthData(JSON.stringify({ ...formValue }));
    setRememberMe(String(rememberMe));

    const integrateData = [
      {
        accessToken: accessToken,
        refreshToken: refreshToken,
        accessTokenExpiresIn: accessTokenExpiresIn,
        refreshTokenExpiresIn: refreshTokenExpiresIn,
      },
      {
        bud: resp.data.bud,
        companyId: resp.data.companyId,
        direktorat: resp.data.direktorat,
        divisi: resp.data.divisi,
        fullName: resp.data.fullName,
        photo: resp.data.photo,
        role: resp.data.role,
        userId: resp.data.sub,
        billingAccount: resp.data.billingAccount
      },
      { ...formValue },
    ];

    integrateData.forEach((item: any, idx: number) => {
      encryptStorage(item, idx);
    });
    return Promise.resolve(resp);
  } catch (err: any) {
    return Promise.reject(err);
  }
  // }
};

export default useSubmitLogin;
