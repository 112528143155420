import callAPI, { callAPIGenerateToken } from "@helpers/network";
import LoginServices from "@services/login";

export const LOGIN_SERVICES = {
  POST_GENERATE_TOKEN: async () => {
    return callAPIGenerateToken(LoginServices.PostGenerateToken());
  },
  POST_LOGIN: async (
    params: {
      accessToken: string;
      loginInput: { username?: string; password?: string; firebaseToken: string };
    },
    lang: string,
    appcategory: string,
  ) => {
    return callAPI(LoginServices.PostLogin(params, lang, appcategory));
  },
};
