import React, { useEffect } from "react";
import { FormControlLabel, Checkbox, Box } from "@mui/material";
import VisiblePassword from "@fragments/VisiblePassword/visiblePassword";
import { bottomDeck, forgotPasswordLink } from "./style";
import useAction from "./hooks/useAction";
import Text from "@ds/components/Text";
import Link from "next/link";
import Button from "@ds/components/Button";
import { Field, Form, Formik, FormikProps, getIn } from "formik";
import { TLoginForm } from "./type";
import FormControl from "@ds/components/FormControl";
import InputBase from "@ds/components/InputBase";
import validateForm from "./validate";
import { useTranslation, withTranslation } from "next-i18next";
import IconChatHelp from "@icons/IconChatHelp";

const Login: React.FC = () => {
  const {
    handleClickPass,
    visiblePass,
    onRememberChange,
    onSubmitForm,
    initialValueForm,
    isLoadButton,
    isChecked,
    errorMessage,
    counter,
    setErrorMessage,
    language,
    onHelpClick,
    isSM,
  } = useAction();
  const { t } = useTranslation("login");
  useEffect(() => {
    if (counter === 0) {
      setErrorMessage(null);
    }
  }, [counter]);

  const renderErrorMessage = errorMessage && !isSM && (
    <Box width="328px" textAlign="center" mb={1} mt={2}>
      {language === "en" ? (
        <Text color="primary-main" variant="body2">
          {counter > 0
            ? `You have entered the wrong password 3 times.\n
              Please try again after ${counter} seconds.`
            : errorMessage}
        </Text>
      ) : (
        <Text color="primary-main" variant="body2">
          {counter > 0
            ? `Anda 3 kali salah memasukkan kata sandi.\n
              Silakan coba lagi setelah ${counter} detik.`
            : errorMessage}
        </Text>
      )}
    </Box>
  );

  return (
    <Box>
      {renderErrorMessage}
      <Formik
        initialValues={initialValueForm}
        validationSchema={validateForm}
        enableReinitialize={true}
        onSubmit={onSubmitForm}
      >
        {(props: FormikProps<Partial<TLoginForm>>) => {
          const { isValid, values } = props;
          const isValidValues = Object.values(values).some((item) => item);
          return (
            <Form>
              <Box my={2} width={isSM ? "auto" : "328px"}>
                <Field
                  name="username"
                  children={({ field, form, hint }: any) => {
                    const { value } = field;
                    const { touched, errors, setFieldValue } = form;
                    const isError =
                      (getIn(touched, field.name) && getIn(errors, field.name)) || !!errorMessage;
                    return (
                      <FormControl
                        label={t("label_email")}
                        error={isError}
                        form={form}
                        field={field}
                        hint={hint}
                      >
                        <InputBase
                          value={value}
                          placeholder={t("placeholders_username")}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setFieldValue([field.name], event.target.value);
                          }}
                        />
                      </FormControl>
                    );
                  }}
                />
              </Box>
              <Box my={2}>
                <Field
                  name="password"
                  children={({ field, form, hint }: any) => {
                    const { value } = field;
                    const { touched, errors, setFieldValue } = form;
                    const isError =
                      (getIn(touched, field.name) && getIn(errors, field.name)) || !!errorMessage;
                    return (
                      <FormControl
                        label={t("label_password")}
                        error={isError}
                        form={form}
                        field={field}
                        hint={hint}
                      >
                        <InputBase
                          placeholder={t("placeholders_password")}
                          value={value}
                          type={visiblePass ? "text" : "password"}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setFieldValue([field.name], event.target.value);
                          }}
                          endAdornment={
                            <VisiblePassword
                              visiblePass={visiblePass}
                              handleClickPass={handleClickPass}
                            />
                          }
                        />
                      </FormControl>
                    );
                  }}
                />
              </Box>
              <Box css={bottomDeck} my={2}>
                <Box>
                  <FormControlLabel
                    control={
                      <Checkbox checked={isChecked} size="small" onChange={onRememberChange} />
                    }
                    label={
                      <Text component="p" color="general-mid" variant="body2">
                        {t("remember")}
                      </Text>
                    }
                  />
                </Box>
                <Box>
                  <Link href="/lupa-password/konfirmasi-username" passHref>
                    <a>
                      <Text css={forgotPasswordLink}>{t("forgot")}</Text>
                    </a>
                  </Link>
                </Box>
              </Box>
              <Button
                type="submit"
                width="100%"
                size="large"
                loading={isLoadButton}
                disabled={!(isValidValues && isValid) || !!(counter > 0)}
              >
                {t("title")}
              </Button>
            </Form>
          );
        }}
      </Formik>
      {isSM && (
        <Button
          width="100%"
          variant="text"
          sx={{ marginTop: "30px", fontWeight: 700 }}
          startIcon={<IconChatHelp fill="black" />}
          onClick={onHelpClick}
        >
          <Text variant="subtitle1">{t("helper")}</Text>
        </Button>
      )}
    </Box>
  );
};

export default withTranslation("login")(Login);
