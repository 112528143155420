import { css } from "@emotion/react";

export const forgotPasswordLink = css({
  fontFamily: "Nunito, sans-serif",
  fontWeight: 400,
  color: "#C72037",
  fontSize: "14px",
  fontStyle: "normal",
  backgroundColor: "inherit",
  textDecoration: "none",
  cursor: "pointer",
});

export const bottomDeck = css({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "baseline",
});

export const ticker = css({
  borderRadius: "100px",
  width: "48px",
  height: "48px",
  padding: "12px",
  textAlign: "center",
  color: "inherit",
  backgroundColor: "#E2E4E5",
});
